@mixin dropdown-arrow()
{
    position: absolute;
    right: 1rem;

    top: 50%;
    transform: translateY(-50%);

    width: 18px;
    height: 10px;
}