/*
 * Styles for /wp-login.php
 */
/* utils */
@import "utils/utils";

/* reset */
@import "common/reset";
@import "common/screen-reader";

.login {
    background: white;
    /* Set the size of the login logo */
    h1 a {
        background-image: url("../img/logo-login.svg");
        background-size: 250px;
        width: 320px;
        height: 75px;
    }
    /* Hide the "Back to (website)" link */
    p#backtoblog {
        display: none;
    }
    /* Hide the "Log in" link on the last password page, as it's pointless*/
    &.login-action-lostpassword p#nav {
        display: none;
    }
    /* Center align the Lost Password link */
    &.login-action-login p#nav {
        text-align: center;
    }
    #loginform {
        border: 0;
    }
    input#wp-submit {
        @include background-color(--primary);
        @include border-colour(--primary);

        transition: all 0.5s;

        &:hover,
        &:focus {
            @include background-color(--secondary);
            @include border-colour(--secondary);
        }
    }
    .message {
        border-left: 4px solid;
        @include border-colour(--primary);
    }
    form {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .button-primary {
        text-shadow: none;
    }
    p#nav a {
        @include colour(--primary);

        transition: color 0.5s;

        &:focus,
        &:hover {
            @include colour(--secondary);
        }
    }
}

// Match colours to modal shown when the login cookie expires
.wp-admin {
    #wp-auth-check-wrap #wp-auth-check {
        background-color: white;
    }
}
