// Place element below top navigation bar and stretch vertically to fill screen
@mixin stretch-below-top-nav {
    height: calc(100vh - 28px); // Height of the top nav
    top: 28px;

    .admin-bar & {
        height: calc(100vh - 74px); // Top Nav + Admin bar
        top: 74px;
    }

    @include bp(md){
        height: calc(100vh - 36px);
        top: 36px;

        .admin-bar & {
            height: calc(100vh - 82px); // Top Nav + Admin bar
            top: 82px;
        }
    }

    @include bp(783px){
        height: calc(100vh - 36px);
        top: 36px;

        .admin-bar & {
            height: calc(100vh - 68px); // Top Nav + Admin bar
            top: 68px;
        }
    }
}