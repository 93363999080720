@mixin grow-arrow() {
    .tail,
    .head {
        transition: transform 0.35s ease;
    }
    .tail {
        transform: translateX(8px);
    }
    .head {
        transform: translateX(-8px);
    }
    &:focus,
    &:hover {
        .tail {
            transform: translateX(2px);
        }
        .head {
            transform: translateX(-2px);
        }
    }
}
