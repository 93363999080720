@mixin gradient {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        background: linear-gradient(rgba(31, 36, 87, 0.5) 20%, rgba(0, 0, 0,0));
    }
}