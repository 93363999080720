@mixin custom-radio {
    label {
        position: relative;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid;
    
            @include border-colour(--primary);
    
            display: inline-block;
            border-radius: 100%;
            margin-right: 0.375rem;
            vertical-align: middle;
        }
    }

    input[type="radio"]{
        @include sr-only();

        &:focus {
            + label::before {
                outline: 2px solid;
                @include prop(outline-color, --primary);
            }
        }

        &:checked {
            + label::before {
                background-image: url('../img/radio-circle.svg');
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
}