@mixin is-touchscreen() {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}
@mixin is-not-touchscreen() {
    @media (hover: hover), (pointer: fine) {
        @content;
    }
}
