@mixin fadeOut($duration){
    visibility: hidden;
    opacity: 0;

    transition: opacity $duration, visibility 0s $duration;
}

@mixin fadeIn($opacity, $duration){
    opacity: $opacity;
    visibility: visible;
    transition: opacity $duration, visibility 0s;
}