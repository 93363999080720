@mixin focus-halo($width, $height)
{
    position: relative;
    overflow: visible; // IE fix

    &::before {
        content: "";
        @include background-color(--white);
        opacity: 0.3;
        position: absolute;

        width: #{$width*2}px;
        height: #{$height*2}px;

        top: -50%;
        left: -50%;

        display: none;
    }

    &:focus {
        outline: 0;
        &::before {
            display: inline-block;
        }
    }
}