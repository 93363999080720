// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/
// Updated: https://core.trac.wordpress.org/ticket/40970
@mixin sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

@mixin sr-only-show-on-focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    @include z-index(screen-reader-text);
}

// WP Core convention
@mixin screen-reader-text {
    @include sr-only();
}
