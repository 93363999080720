/*
 * This is Andy Bell's modern CSS reset
 * Modern browsers don't need the normalize.css heavy approach
 * @link https://hankchizljaw.com/wrote/a-modern-css-reset/
 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class]:not(.alignwide):not(.alignfull),
ol[class]:not(.alignwide):not(.alignfull) {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class]:not(.alignwide):not(.alignfull),
ol[class]:not(.alignwide):not(.alignfull),
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: white;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class]:not(.alignwide):not(.alignfull),
ol[class]:not(.alignwide):not(.alignfull) {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default
 * This is opinionated and intended to make working with component-based systems easier
 * If you don't know about the lobotomised owl, search that term for the ALA article
 * @link https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/
 */
article > * + * {
  // margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
