/**
 * Project variables
 *
 * All the Sass variables used throughout the theme. This file shouldn't output
 * any actual CSS on compilation.
 */

/* Colours */

// Base colours
$grey-1: #f6f7fa; // Custom Grey
$grey-2: #f1f1f1; // Custom Grey

$grey-3: #eeeeee;
$grey-4: #e0e0e0;
$grey-5: #bdbdbd;
$grey-6: #9e9e9e;
$grey-7: #757575;
$grey-8: #616161;
$grey-9: #424242;
$grey-10: #212121;

/*
 * Colour variables.
 *
 * If you alter variable names, also update EditorColourPalette.php
 * These cascade to block editor colour palette.
 *
 * Sass variables need to be #{interpolated}
 *
 * Don't use --text as this causes a collision with WordPress default classes.
 */
$colour-map: (
    --primary: #1f2457,
    --primary-text: #fff,
    --primary-hover: #3b8fb4,
    --secondary: #3b8fb4,
    --secondary-text: #fff,
    --secondary-hover: #1f2457,
    --tertiary: #f1f1f1,
    --tertiary-text: #1f2457,
    --tertiary-hover: darken(#f1f1f1, 10%),
    --gold: #c1c020,
    --white: #fff,
    --white-text: #1f2457,
    --white-hover: #{$grey-3},
    --body-text: #1f2457,
    --link: #3b8fb4,
    --error: #dd0000,
    --black: #000,
    --grey-1: #{$grey-1},
    --grey-2: #{$grey-2},
);

/*
 * Layout variables.
 *
 * Used for the wrapper and more layout options.
 */
$layout-map: (
    --horizontal-gap: 1rem,
    --vertical-gap: 0,
    --max-content-width: 730px,
    --max-container-width: 1110px,
);

/**
 * Z-Index variables.
 *
 * Z-Indicies should be defined in increments of 5 (avoid 99999999999 etc.)
 */
$z-index-map: (
    cover-block-video-background: 0,
    cover-block-background-gradient: 5,
    cover-block-inner: 5,
    old-cover-block-text: 5,
    screen-reader-text: 100000,
    // Above WP Toolbar,
    header-button: 200,
    header: 1000,
    search-overlay: 1005,
    search-area: 5,
    testimonial-image: 5,
    apply-option: 5,
);

// merge maps to be output in the :root
$root-variables: map-merge($colour-map, $layout-map);

// Base background-color
$base-background-colour: white;

/* Typography */

// Base typography
$base-font-size: 16px;
$base-line-height: 1.5;
$base-font-weight: 400;

// Font stacks
$heading-font: "Bebas Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; // System font stack
$serif: Georgia, "Times New Roman", Times, serif;
$monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

// Default font
$base-font: $sans-serif;

/*
 * Breakpoint variables.
 *
 * Used for bp() mixin and bp() function
 */
$breakpoint-map: (
    sm: 320px,
    md: 768px,
    lg: 1400px,
    xl: 1600px,
);

/**
 * SVG icon defaults
 */
$icon-height: 44px; //Recommended minimum size from Apples Human Interface Guidelines
$icon-width: 44px; //Recommended minimum size from Apples Human Interface Guidelines
