/**
 * Button mixins.
 *
 * For WordPress to work with buttons more efficiently, we seperate colours from
 * the rest of the button styles. This reduces the amount of duplicate CSS when
 * compiled.
 *
 * Buttons have the class .link-button.
 * Additional classes can be a combination of:
 *   .is-style-full
 *   .is-style-outline
 *   .has-[colour-name]-button-color
*/

/**
 * The button styles.
 */
@mixin button() {
    -webkit-appearance: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    white-space: nowrap;

    font-family: $heading-font;
    text-transform: uppercase;

    border: 0;
    font-size: 1.125rem;

    position: relative;
    transition: 0.3s color;
    z-index: 1; // IE Fix

    padding: 0.625rem 1.25rem;

    &:hover,
    &:focus,
    &:active,
    &.active {
        text-decoration: none;
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
    }
    &:before {
        z-index: -2;
    }
    &:after {
        z-index: -1;
        visibility: hidden;
        height: 100%;
        transform: scaleX(0);
        transform-origin: right;
        transition: 0.6s transform cubic-bezier(0, 0.01, 0, 1),
            visibility 0s 0.4s;
    }
    &:focus::after,
    &:hover::after {
        width: 100%;
        visibility: visible;
        transform: scaleX(1);
        transform-origin: left;
        transition: 0.4s transform cubic-bezier(0, 0.01, 0, 1);
    }
}

/**
 * The colours for a regular button.
 */
@mixin button-colours($color) {
    @include colour(#{$color}-text);
    @include border-colour($color);

    &:before {
        @include background-colour($color);
    }

    &:after {
        @include background-colour(#{$color}-hover);
    }
}

/**
 * The colours for an outlined button.
 */
@mixin button-outline-colours($color) {
    @include colour($color);
    border: 2px solid transparent;
    background-color: transparent;
    @include border-colour($color);

    padding: 0.5rem 1.125rem; // -2px to account for the border

    &::before {
        background-color: transparent;
    }
    &::after {
        @include background-colour($color);
    }
    &:focus,
    &:hover {
        @include colour(#{$color}-text);
    }
}

@mixin button-unstyled {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    &:active {
        transform: scale(0.99);
    }
    &:focus {
        // outline: 1px solid #fff;
        // outline-offset: -4px;
    }
}
